<template>
<div>
    <b-row v-if="checkPermissions('002-PRO-MAP','c') == 1" class="justify-content-center">
        <b-col lg="10" class="text-right mt-2 mb-3">
            <b-button variant="dark" class=" mr-2" @click="modalRegistrarProceso = true">
                <i class="fas fa-plus fa-sm"></i>
                Nuevo proceso
            </b-button>
        </b-col>
    </b-row>
    <b-row class="justify-content-center text-center ">
        <b-col lg="1">
            <div class="flex-bubble-lateral-primary flex-bubble-lateral-primary-left">
                <i class="fas fa-comments"></i><br>
                <div>Requisitos<br>del cliente</div>
            </div>
        </b-col>
        <b-col lg="10">
            <div class="flex-title">
                <i class="fas fa-cogs"></i><span> Mapa de procesos</span>
            </div>
            <div class="flex-container">
                <div class="flex-child border-flex-child-green">
                    <div class="flex-bubble-lateral flex-bubble-lateral-green">
                        <i class="fas fa-lightbulb"></i>
                        <div>Procesos<br>estratégicos</div>
                    </div>
                    <div v-for="proceso in listaProcesos.filter(x => x.tipoProceso == 1)" :key="proceso.idProceso" class="flex-sub-child content-flex-sub-child-green  mt-3 ">
                        <router-link :to="{name: 'Indicadores del proceso', params: {name: proceso.nombre,id: proceso.idProceso }}" class="text-white">
                            <h3>{{proceso.nombre}}</h3>
                        </router-link>

                        <b-button size="sm" class=" mr-1 mt-1" variant="blue" @click="descargarDocumento(documento.urlArchivo)" v-for="documento in listaDocumentacion.filter(x => x.idProceso == proceso.idProceso)" :key="documento.idDocumentacion">
                            <i class="fas fa-file-alt"></i><span v-if="documento.codigo !=''"> {{documento.codigo}} -</span> {{documento.nombre}}
                        </b-button>
                    </div>

                </div>

                <div class="flex-child border-flex-child-orange mt-4">
                    <div class="flex-bubble-lateral flex-bubble-lateral-orange">
                        <i class="fas fa-flag" aria-hidden="true"></i>
                        <div>Procesos<br>misionales</div>
                    </div>
                    <div v-for="proceso in listaProcesos.filter(x => x.tipoProceso == 2)" :key="proceso.idProceso" class="flex-sub-child content-flex-sub-child-orange">
                        <router-link :to="{name: 'Indicadores del proceso', params: {name: proceso.nombre,id: proceso.idProceso }}" class="text-white">
                            <h3>{{proceso.nombre}}</h3>
                        </router-link>

                        <b-button size="sm" class=" mr-1 mt-1" variant="blue" @click="descargarDocumento(documento.urlArchivo)" v-for="documento in listaDocumentacion.filter(x => x.idProceso == proceso.idProceso)" :key="documento.idDocumentacion">
                            <i class="fas fa-file-alt"></i><span v-if="documento.codigo !=''"> {{documento.codigo}} -</span> {{documento.nombre}}
                        </b-button>
                    </div>

                </div>
                <div class="flex-child border-flex-child-yellow mt-4">
                    <div class="flex-bubble-lateral flex-bubble-lateral-yellow">
                        <i class="fas fa-handshake"></i>
                        <div>Procesos<br>de apoyo</div>
                    </div>
                    <div v-for="proceso in listaProcesos.filter(x => x.tipoProceso == 3)" :key="proceso.idProceso" class="flex-sub-child content-flex-sub-child-yellow">
                        <router-link :to="{name: 'Indicadores del proceso', params: {name: proceso.nombre,id: proceso.idProceso }}" class="text-white">
                            <h3>{{proceso.nombre}}</h3>
                        </router-link>

                        <b-button size="sm" class=" mr-1 mt-1" variant="blue" @click="descargarDocumento(documento.urlArchivo)" v-for="documento in listaDocumentacion.filter(x => x.idProceso == proceso.idProceso)" :key="documento.idDocumentacion">
                            <i class="fas fa-file-alt"></i><span v-if="documento.codigo !=''"> {{documento.codigo}} -</span> {{documento.nombre}}
                        </b-button>
                    </div>

                </div>
            </div>
        </b-col>
        <b-col lg="1">
            <div class="flex-bubble-lateral-primary flex-bubble-lateral-primary-right">
                <i class="fas fa-smile"></i><br>
                <div>Satisfacción<br>del cliente</div>
            </div>
        </b-col>
    </b-row>
    <b-row class="mt-5">
        <b-col lg="1">
        </b-col>
        <b-col lg="10">
            <CCard accent-color="primario">
                <CCardBody>
                    <b-tabs content-class="mt-3" active-nav-item-class="font-weight-bold text-uppercase text-dark">
                        <b-tab title="Documentación" active>
                            <b-row>
                                <b-col md="12" class="text-right mb-3">
                                    <b-button size="sm" variant="dark" class=" mr-2" @click="modalRegistrarDocumentacion = true">
                                        <i class="fas fa-plus fa-sm"></i> Nueva documentación
                                    </b-button>
                                </b-col>
                                <b-col md="12">
                                    <b-table hover bordered show-empty small responsive outlined :items="listaDocumentacionMapa" :fields="camposDocumentacionMapa" empty-text="Aún no hay datos en esta sección.">
                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                        <template v-slot:cell(opciones)="param">
                                            <b-button size="sm" class=" mr-1 mb-1" variant="blue" @click="descargarDocumento(param.item.urlArchivo)" v-c-tooltip.hover.click="'Descargar'">
                                                <i class="fas fa-download my-0 mx-0"></i>
                                            </b-button>
                                            <template>
                                                <b-button size="sm" variant="danger mr-1 mb-1" @click="eliminarDocumentacion(param)" v-c-tooltip.hover.click="'Eliminar'">
                                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                </b-button>
                                            </template>
                                        </template>
                                        <template v-slot:cell(fechaCreacion)="data">
                                            {{ data.item.fechaCreacion.substr(0,10)}}</template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-tab>
                        <b-tab title="Tipo de documentos">
                            <b-row>
                                <b-col md="12" class="text-right mb-3">
                                    <b-button size="sm" variant="dark" class=" mr-2" @click="modalRegistrarTipoDocumentacion = true">
                                        <i class="fas fa-plus fa-sm"></i>
                                        Nuevo tipo de documento
                                    </b-button>
                                </b-col>
                                <b-col md="12">
                                    <div class="mb-3">
                                        <span class="text-muted h6">LISTADO DE TIPOS DE DOCUMENTOS</span>
                                    </div>
                                    <b-table bordered hover show-empty small responsive outlined :items="listaTipoDocumentacion" :fields="camposTipoDocumentacion" empty-text="Aún no hay datos en esta sección.">
                                        <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                        <template v-slot:cell(opciones)="param">
                                            <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirModalModificarTipoDocumento(param)" variant="dark" class=" mr-1 mb-1">
                                                <span class="btn-inner--icon">
                                                    <i class="fas fa-search my-0 mx-0"></i>
                                                </span>
                                            </b-button>
                                            <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarTipoDocumento(param)" variant="danger">
                                                <span class="btn-inner--icon">
                                                    <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                                </span>
                                            </b-button>
                                        </template>
                                        <template #cell(ejemplos)="param">
                                            {{param.item.ejemplos | truncate(30)}}
                                        </template>
                                        <template #cell(observaciones)="param">
                                            {{param.item.observaciones | truncate(30)}}
                                        </template>
                                    </b-table>
                                </b-col>
                            </b-row>
                        </b-tab>
                    </b-tabs>

                </CCardBody>
            </CCard>
        </b-col>
        <b-col lg="1">
        </b-col>
    </b-row>

    <CModal :show.sync="modalRegistrarProceso" :closeOnBackdrop="false" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo proceso </span>
            </h6>
            <CButtonClose @click="modalRegistrarProceso = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarProceso)">
                <b-row>
                    <b-col lg="6">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosProceso.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6">
                        <b-form-group label="Tipo Proceso: ">
                            <b-form-radio-group small plain v-model="datosProceso.tipoProceso" :options="radioTipoProceso"></b-form-radio-group>
                        </b-form-group>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="descripcion" :rules="{max:255}" v-slot="validationContext">
                            <b-form-group label="Descripción:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.descripcion" :state="getValidationState(validationContext)" placeholder="Ingrese la descripción" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="referencias" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Referencias documentales:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.referenciasDocumentales" :state="getValidationState(validationContext)" placeholder="Ingrese las referencias" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="proveedores principales" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Proveedores Principales:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.proveedoresPrincipales" :state="getValidationState(validationContext)" placeholder="Ingrese los proveedores principales" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="4">
                        <validation-provider name="proveedores" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Proveedores:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.proveedores" :state="getValidationState(validationContext)" placeholder="Ingrese los proveedores" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="entradas" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Entradas:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.entradas" :state="getValidationState(validationContext)" placeholder="Ingrese las entradas" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="responsable" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Responsable:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.responsable" :state="getValidationState(validationContext)" placeholder="Ingrese el responsable" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="recursos" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Recursos:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.recursos" :state="getValidationState(validationContext)" placeholder="Ingrese los recursos" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="clientes" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Clientes:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.clientes" :state="getValidationState(validationContext)" placeholder="Ingrese los clientes" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="salidas" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Salidas:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.salidas" :state="getValidationState(validationContext)" placeholder="Ingrese las salidas" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="registros" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Registros:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.registros" :state="getValidationState(validationContext)" placeholder="Ingrese los registros" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="inspecciones" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Inspecciones:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.inspecciones" :state="getValidationState(validationContext)" placeholder="Ingrese las inspecciones" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="4">
                        <validation-provider name="Indicaciones / Riesgos" :rules="{required: true, max:255}" v-slot="validationContext">
                            <b-form-group label="Indicaciones / Riesgos:" class="mb-2">
                                <b-form-textarea v-model="datosProceso.indicacionesRiesgos" :state="getValidationState(validationContext)" placeholder="Ingrese las indicaciones" rows="3" max-rows="6"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class="mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarProceso = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalRegistrarDocumentacion" :closeOnBackdrop="false" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nueva documentación </span>
            </h6>
            <CButtonClose @click="modalRegistrarDocumentacion = false" class="text-white" />
        </template>
        <validation-observer ref="observerx" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarDocumentacionMapa)">
                <b-row>
                    <b-col md="12">
                        <validation-provider name="nombre" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nombre" v-model="datosDocumentacionMapa.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider :rules="{required: true}" name="archivo" v-slot="validationContext">
                            <b-form-group label="Archivo:" class="mb-2">
                                <b-form-file ref="file" v-model="datosDocumentacionMapa.archivoMeta" :state="(getValidationState(validationContext) )" placeholder="Elija un archivo o arrástrelo aquí..." :accept="accept.toString()" browse-text="Subir" v-on:change="handleFileUpload"></b-form-file>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class="mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarDocumentacion = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalRegistrarTipoDocumentacion" size="lg" :closeOnBackdrop="false" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Nuevo tipo de documento </span>
            </h6>
            <CButtonClose @click="modalRegistrarTipoDocumentacion = false" class="text-white" />
        </template>
        <validation-observer ref="observer3" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarTipoDocumentacion)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="nombre de documento" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre de documento:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese nombre de documento" v-model="datosTipoDocumentacion.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="código" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Código:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese código" v-model="datosTipoDocumentacion.codigo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="sub-documentos" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Sub-documentos:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese sub-documentos" v-model="datosTipoDocumentacion.subDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="ejemplos" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Ejemplos:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese ejemplos" v-model="datosTipoDocumentacion.ejemplos"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="observaciones" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese observaciones" v-model="datosTipoDocumentacion.observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class="mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarTipoDocumentacion = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalModificarTipoDocumentacion" size="lg" :closeOnBackdrop="false" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5"> Detalle tipo de documento </span>
            </h6>
            <CButtonClose @click="modalModificarTipoDocumentacion = false" class="text-white" />
        </template>
        <validation-observer ref="observer4" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(modificarTipoDocumentacion)">
                <b-row>
                    <b-col md="4">
                        <validation-provider name="nombre de documento" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Nombre de documento:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese nombre de documento" v-model="datosActualizarTipoDocumentacion.nombre"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="código" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Código:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese código" v-model="datosActualizarTipoDocumentacion.codigo"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="4">
                        <validation-provider name="sub-documentos" :rules="{}" v-slot="validationContext">
                            <b-form-group label="Sub-documentos:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese sub-documentos" v-model="datosActualizarTipoDocumentacion.subDocumento"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="ejemplos" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Ejemplos:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese ejemplos" v-model="datosActualizarTipoDocumentacion.ejemplos"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="observaciones" :rules="{ }" v-slot="validationContext">
                            <b-form-group label="Observaciones:" class="mb-2">
                                <b-form-textarea rows="3" max-rows="6" :state="getValidationState(validationContext)" placeholder="Ingrese observaciones" v-model="datosActualizarTipoDocumentacion.observaciones"></b-form-textarea>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class="mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalModificarTipoDocumentacion = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    data() {
        return {
            disabled: false,

            listaProcesos: [],
            listaDocumentacion: [],
            listaDocumentacionMapa: [],
            listaTipoDocumentacion: [],
            modalRegistrarTipoDocumentacion: false,
            modalModificarTipoDocumentacion: false,

            camposTipoDocumentacion: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "nombre",
                    label: "Documento",
                    class: "text-center",
                },
                {
                    key: "codigo",
                    label: "Codígo",
                    class: "text-center",
                },
                {
                    key: "subDocumento",
                    label: "Sub-documento",
                    class: "text-center",
                },
                {
                    key: "ejemplos",
                    label: "Ejemplos",
                    class: "text-center",
                },
                {
                    key: "observaciones",
                    label: "Observaciones",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                },
            ],

            camposDocumentacionMapa: [{
                    key: "index",
                    label: "N°",
                    class: "text-center"
                },
                {
                    key: "nombre",
                    label: "Documento",
                    class: "text-center"
                },

                {
                    key: "fechaCreacion",
                    label: "Fecha",
                    class: "text-center"
                },
                {
                    label: "",
                    key: "opciones",
                    class: "text-center"
                },
            ],

            listaDocumentacion: [],

            datosProceso: {
                idCliente: '',
                nombre: '',
                tipoProceso: 1,
                descripcion: '',
                referenciasDocumentales: '',
                proveedoresPrincipales: '',
                proveedores: '',
                entradas: '',
                responsable: '',
                recursos: '',
                clientes: '',
                salidas: '',
                registros: '',
                inspecciones: '',
                indicacionesRiesgos: '',
            },
            datosTipoDocumentacion: {
                nombre: '',
                codigo: '',
                subDocumento: '',
                ejemplos: '',
                observaciones: ''
            },
            datosActualizarTipoDocumentacion: {
                idTipoDocumentacion: '',
                nombre: '',
                codigo: '',
                subDocumento: '',
                ejemplos: '',
                observaciones: ''
            },

            datosDocumentacionMapa: {
                nombre: '',
                archivoMeta: null,
            },

            radioTipoProceso: [{
                    value: "1",
                    text: "Estratégicos"
                },
                {
                    value: "2",
                    text: "Misionales"
                },
                {
                    value: "3",
                    text: "Apoyo"
                }
            ],

            modalRegistrarProceso: false,
            modalRegistrarDocumentacion: false,
            accept: [
                '.vsd', '.vsdx', '.xlsx', '.xls', '.csv',
                '.pdf', '.doc', '.docx', '.ppt', '.pptx',
                '.png', '.jpg', '.jpeg', '.pdf', '.docx',
                'application/msword',
                'application/vnd.ms-excel'
            ]

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        handleFileUpload(e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(vsd|vsdx|xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg|pdf|docx)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs["file"].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs["file"].reset();
                    return;
                }
                this.datosDocumentacionMapa.archivoMeta = this.$refs.file.files[0];
            }
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        resetModalRegistrarProceso() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosProceso.nombre = '';
            this.datosProceso.tipoProceso = 1;
            this.datosProceso.descripcion = '';
            this.datosProceso.referenciasDocumentales = '';
            this.datosProceso.proveedores = '';
            this.datosProceso.proveedoresPrincipales = '';
            this.datosProceso.entradas = '';
            this.datosProceso.responsable = '';
            this.datosProceso.recursos = '';
            this.datosProceso.clientes = '';
            this.datosProceso.inspecciones = '';
            this.datosProceso.salidas = '';
            this.datosProceso.registros = '';
            this.datosProceso.indicacionesRiesgos = '';
        },
        resetModalRegistrarDocumentacion() {
            this.$nextTick(() => {
                this.$refs.observerx.reset();
            });
            this.datosDocumentacionMapa.nombre = '';
            this.datosDocumentacionMapa.archivoMeta = null;

        },
        registrarProceso() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-proceso",
                    me.datosProceso, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarProceso = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarProcesos();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        listarProcesos() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-procesos", {
                        params: {
                            idCliente: me.datosProceso.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.listaProcesos = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarDocumentacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-documentacion", {
                        params: {
                            idCliente: me.datosProceso.idCliente,
                        },
                    }
                )
                .then(function (response) {
                    me.listaDocumentacion = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarDocumentacionMapa() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-documentacion-mapa", {
                        params: {
                            idCliente: me.datosProceso.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaDocumentacionMapa = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        listarTipoDocumentacion() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-tipo-documentacion", {
                        params: {
                            idCliente: me.datosProceso.idCliente
                        },
                    }
                )
                .then(function (response) {
                    me.listaTipoDocumentacion = response.data
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        descargarDocumento(url) {
            window.open(url)
        },
        registrarDocumentacionMapa() {
            let me = this;
            me.disabled = true;

            const formData = new FormData();

            formData.append("file", me.datosDocumentacionMapa.archivoMeta);
            formData.append("folder", 'procesos-y-objetivos/mapa-de-procesos');

            formData.append("idCliente", me.datosProceso.idCliente);
            formData.append("nombre", me.datosDocumentacionMapa.nombre);
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-documentacion-mapa",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.modalRegistrarDocumentacion = false;
                    me.listarDocumentacionMapa();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        registrarTipoDocumentacion() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-tipo-documentacion", {
                        datosTipoDocumentacion: me.datosTipoDocumentacion,
                        idCliente: me.datosProceso.idCliente
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalRegistrarTipoDocumentacion = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarTipoDocumentacion();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        modificarTipoDocumentacion() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-tipo-documentacion", {
                        datosTipoDocumentacion: me.datosActualizarTipoDocumentacion,
                        idCliente: me.datosProceso.idCliente
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    me.modalModificarTipoDocumentacion = false
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.listarTipoDocumentacion();
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });
        },
        abrirModalModificarTipoDocumento(param) {
            let me = this;
            me.datosActualizarTipoDocumentacion.idTipoDocumentacion = param.item.idTipoDocumentacion;
            me.datosActualizarTipoDocumentacion.nombre = param.item.nombre;
            me.datosActualizarTipoDocumentacion.codigo = param.item.codigo;
            me.datosActualizarTipoDocumentacion.subDocumento = param.item.subDocumento;
            me.datosActualizarTipoDocumentacion.ejemplos = param.item.ejemplos;
            me.datosActualizarTipoDocumentacion.observaciones = param.item.observaciones;

            me.modalModificarTipoDocumentacion = true;
        },
        eliminarTipoDocumento(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el tipo de documento?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-tipo-documentacion", {
                                idTipoDocumentacion: param.item.idTipoDocumentacion
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarTipoDocumentacion();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        resetModalRegistrarTipoDocumentacion() {
            this.$nextTick(() => {
                this.$refs.observer3.reset();
            });
            this.datosTipoDocumentacion.nombre = '';
            this.datosTipoDocumentacion.codigo = '';
            this.datosTipoDocumentacion.subDocumento = '';
            this.datosTipoDocumentacion.ejemplos = '';
            this.datosTipoDocumentacion.observaciones = '';

        },
        resetModalModificarTipoDocumentacion() {
            this.$nextTick(() => {
                this.$refs.observer4.reset();
            });

        },
        eliminarDocumentacion(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el documento?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    let me = this;
                    axios
                        .post(
                            CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-documentacion-mapa", {
                                idDocumentacion: param.item.idDocumentacion
                            }, {
                                headers: {
                                    Authorization: `Bearer ${localStorage.token}`
                                },
                            }
                        )
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarDocumentacionMapa();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {

    },
    watch: {
        modalRegistrarProceso: function (val) {
            if (val == false) {
                this.resetModalRegistrarProceso();
            }
        },
        modalRegistrarDocumentacion: function (val) {
            if (val == false) {
                this.resetModalRegistrarDocumentacion();
            }
        },
        modalRegistrarTipoDocumentacion: function (val) {
            if (val == false) {
                this.resetModalRegistrarTipoDocumentacion();
            }
        },
        modalModificarTipoDocumentacion: function (val) {
            if (val == false) {
                this.resetModalModificarTipoDocumentacion();
            }
        },
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosProceso.idCliente = user.uidClient;
            this.listarProcesos();
            this.listarDocumentacion();
            this.listarDocumentacionMapa();
            this.listarTipoDocumentacion();
        }
    }

}
</script>
